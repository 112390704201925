<template>
  <div>
    <v-card flat class="px-3 mx-sm-5">
      <v-alert
        dismissible
        text
        outlined
        type="error"
        v-if="subscriptionErrorMessage"
        class="mt-4 col-12 text-center"
      >
        {{ subscriptionErrorMessage }}
      </v-alert>
      <v-alert
        text
        outlined
        type="info"
        v-if="subscriptionDeactivationMessage"
        class="mt-4 col-12 text-center"
      >
        <span>
          {{ subscriptionDeactivationMessage }}
          {{ getReadableDate(brand.invalid_after) }}
        </span>
      </v-alert>
      <v-alert
        text
        outlined
        type="error"
        v-if="subscriptionDeactivatedMessage"
        class="mt-4 col-12 text-center"
      >
        <span>
          {{ subscriptionDeactivatedMessage }}
          {{ getReadableDate(brand.invalid_after) }}
        </span>
      </v-alert>
      <v-card-title>
        {{
          $t('Subscription')
        }}
        <v-spacer></v-spacer>
        <div v-if="brandSubscription && !disabledBrandSubscriptionAutoRenewal">
          <v-btn
            v-if="
              !subscriptionDeactivationMessage &&
                !subscriptionDeactivatedMessage
            "
            color="error"
            class="mr-2 text-none"
            @click="modalCancelSubscription = true"
          >
            <v-icon small left>
              fas fa-lock
            </v-icon>
            {{ $t('CancelSubscription') }}
          </v-btn>
          <v-btn
            v-else
            color="error"
            class="text-none mr-3"
            @click="modalReactivateSubscription = true"
          >
            <v-icon small left>
              fas fa-lock-open
            </v-icon>
            {{ $t('ReactivateSubscription') }}
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text v-if="brandSubscription" class="pb-0">
        <v-form
          ref="brandSubscriptionForm"
        >
          <v-row class="justify-space-around">
            <v-col class="col-12 col-md-6 pb-0">
              <v-select
                :menu-props="{ offsetY: true }"
                :items="warehouseOffers"
                :item-text="formatedPlan"
                item-value="subscription.plan"
                :label="$t('Plan')"
                v-model="brandSubscription.plan"
                outlined
                :disabled="!disabledBrandSubscriptionAutoRenewal && 
                  !!subscriptionDeactivationMessage ||
                  !!subscriptionDeactivatedMessage 
                "
              >
              </v-select>
              <v-select
                :menu-props="{ offsetY: true }"
                :items="brandSubscriptionPaymentMethod"
                :label="$t('PaymentMethod')"
                v-model="brandSubscription.payment_method"
                outlined
                :disabled="!disabledBrandSubscriptionAutoRenewal &&
                  !!subscriptionDeactivationMessage ||
                  !!subscriptionDeactivatedMessage 
                "
              >
              </v-select>
              <v-select
                :menu-props="{ offsetY: true }"
                :items="brandSubscriptionRecurrence"
                :label="$t('Recurrence')"
                v-model="brandSubscription.recurrence"
                outlined
                :disabled="!disabledBrandSubscriptionAutoRenewal &&
                  !!subscriptionDeactivationMessage ||
                  !!subscriptionDeactivatedMessage 
                "
              >
              </v-select>
              <v-text-field
                type="number"
                v-model="brandSubscription.price"
                :label="$t('Price')"
                outlined
                :disabled="!disabledBrandSubscriptionAutoRenewal &&
                  !!subscriptionDeactivationMessage ||
                  !!subscriptionDeactivatedMessage 
                "
              >
              </v-text-field>
              <v-text-field
                v-model="brandSubscription.purchaseOrder"
                :label="$t('PurchaseOrder')"
                outlined
                :disabled="!disabledBrandSubscriptionAutoRenewal &&
                  !!subscriptionDeactivationMessage ||
                  !!subscriptionDeactivatedMessage 
                "
                :maxlength="29"
                counter
              >
              </v-text-field>
              <v-checkbox
                v-model="brandSubscription.auto_renewal"
                :label="$t('AutoRenewal')"
                class="mt-0 pt-0 mb-2"
                inset
                color="success"
                :disabled="!disabledBrandSubscriptionAutoRenewal &&
                  !!subscriptionDeactivationMessage ||
                  !!subscriptionDeactivatedMessage 
                "
              >
              </v-checkbox>
              <v-menu
                v-if="!brandSubscription.auto_renewal"
                ref="brandInvalidAfterDatePicker"
                v-model="brandInvalidAfterDatePicker"
                :close-on-content-click="false"
                :return-value.sync="brandInvalidAfterFormatDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="brandInvalidAfterFormatDate"
                    :label="$t('endDateSubscription')"
                    append-icon="fas fa-calendar"
                    readonly
                    outlined
                    :disabled="!disabledBrandSubscriptionAutoRenewal && letActiveInvalidAfter && 
                      (!!subscriptionDeactivationMessage ||
                        !!subscriptionDeactivatedMessage) 
                    "
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="brand.invalid_after"
                  :allowed-dates="disablePastDates"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    class="text-none"
                    color="primary"
                    @click="brandInvalidAfterDatePicker = false"
                  >
                    {{ $t('Cancel') }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    class="text-none"
                    @click="$refs.brandInvalidAfterDatePicker.save(brand.invalid_after)"
                  >
                    {{ $t('Confirm') }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-text-field
                v-model="brandSubscription.formattedNextBillingDate"
                :label="$t('nextBillingDate')"
                outlined
                :disabled="true"
              >
              </v-text-field>
              <v-text-field
                type="number"
                v-model="brandSubscription.trial_period"
                :rules="rules.rulesTrialPeriod"
                :label="$t('trialPeriod')"
                :disabled="
                  currentTrialPeriod === 0
                "
                outlined
                :hint="readableEndOfTrialPeriod"
                :persistent-hint="readableEndOfTrialPeriod !== ''"
                @input="updateReadableEndOfTrialPeriod"
              >
              </v-text-field>
            </v-col>
            <v-col class="col-12 col-md-5 pb-0">
              <v-form ref="discountsForm">
                <v-data-table
                  :headers="headersDiscounts"
                  :items="discounts"
                  mobile-breakpoint="0"
                  :footer-props="{
                    itemsPerPageOptions: [15, 50, 100, -1],
                  }"
                >
                  <template v-slot:top>
                    <v-toolbar
                      flat
                      color="white"
                      style="font-size: 18px"
                      class="pr-5"
                    >
                      {{ $t('discounts') }}
                      <v-spacer></v-spacer>
                      <v-btn
                        fab
                        x-small
                        color="info"
                        @click="addDiscountLine"
                        :disabled="!disabledBrandSubscriptionAutoRenewal && 
                          !!subscriptionDeactivationMessage ||
                          !!subscriptionDeactivatedMessage
                        "
                      >
                        <v-icon small>
                          fas fa-plus
                        </v-icon>
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:item="{ item }">
                    <tr class="align-center border-green">
                      <td>
                        <v-text-field
                          type="number"
                          v-model="item.discountPercentage"
                          :rules="rules.rulesDiscountPercentage"
                          dense
                          :disabled="!disabledBrandSubscriptionAutoRenewal && 
                            !!subscriptionDeactivationMessage ||
                            !!subscriptionDeactivatedMessage
                          "
                        >
                        </v-text-field>
                      </td>
                      <td v-if="!item.lifetime">
                        <v-text-field
                          type="number"
                          v-model="item.durationMonths"
                          :rules="rules.rulesDiscountMonths"
                          dense
                          :disabled="!disabledBrandSubscriptionAutoRenewal && 
                            !!subscriptionDeactivationMessage ||
                            !!subscriptionDeactivatedMessage
                          "
                        >
                        </v-text-field>
                      </td>

                      <td v-else>
                        <v-text-field
                          class="text-center"
                          dense
                          value="Inf"
                          :disabled="!disabledBrandSubscriptionAutoRenewal && 
                            !!subscriptionDeactivationMessage ||
                            !!subscriptionDeactivatedMessage
                          "
                        >
                        </v-text-field>
                      </td>
                      <td class="text-center">
                        <v-checkbox
                          v-model="item.lifetime"
                          :disabled="!disabledBrandSubscriptionAutoRenewal && 
                            !!subscriptionDeactivationMessage ||
                            !!subscriptionDeactivatedMessage
                          "
                        >
                        </v-checkbox>
                      </td>
                      <td>
                        <v-btn
                          small
                          rounded
                          color="error"
                          @click="deleteItem(item)"
                          :disabled="!disabledBrandSubscriptionAutoRenewal && 
                            !!subscriptionDeactivationMessage ||
                            !!subscriptionDeactivatedMessage 
                          "
                        >
                          <v-icon small>
                            fas fa-trash
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-form>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pl-4 pb-8">
        <v-row class="justify-center">
          <v-btn
            color="error"
            class="text-none mr-2"
            :loading="loading"
            :disabled="
              loading ||
                !!subscriptionDeactivatedMessage ||
                !!subscriptionDeactivationMessage && !disabledBrandSubscriptionAutoRenewal
            "
            :ripple="false"
            @click="
              getBrandSubscription()
              getBrandSubscriptionInvoices()
              modifyDate = false
            "
          >
            <v-icon small class="mr-1">
              fa fa-ban
            </v-icon> {{ $t('Cancel') }}
          </v-btn>
          <v-btn
            color="success"
            class="text-none"
            :loading="loading"
            :disabled="
              (loading ||
                !!subscriptionDeactivatedMessage ||
                !!subscriptionDeactivationMessage) && !disabledBrandSubscriptionAutoRenewal && letActiveInvalidAfter
            "
            :ripple="false"
            @click="modifyBrandSubscription"
          >
            <v-icon small class="mr-1">
              fa fa-check
            </v-icon> {{ $t('Submit') }}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-divider class="mx-7"></v-divider>
    <!--
        --
        -- INVOICES LIST
        --
        -->
    <v-card
      v-if="subscriptionInvoices"
      elevation="0"
      flat
      class="px-3 mx-sm-5 mt-2"
    >
      <v-card-title>
        {{
          $t('Invoices')
        }}
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-if="subscriptionInvoices"
          :headers="headersInvoices"
          :items="subscriptionInvoices"
          :footer-props="{
            itemsPerPageOptions: [15, 50, 100, -1],
          }"
          sort-by="date"
          mobile-breakpoint="0"
        >
          <template v-slot:[`item.date`]="{ item }">
            <template>
              <span> {{ item.readableCreated }} </span>
            </template>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            <template>
              <span>{{ item.readablePrice }} </span>
            </template>
          </template>
          <template v-slot:[`item.download`]="{ item }">
            <template>
              <a class="d-none" :ref="`download${item._id}`"></a>
              <v-btn
                color="orange"
                class="text-none white--text"
                target="_blank"
                :loading="item.loadingPdf"
                :disabled="item.disabled"
                @click="getSubscriptionInvoicePDF(item)"
                :ripple="false"
              >
                <v-icon small class="mr-1">
                  fas fa-download
                </v-icon>
                PDF
              </v-btn>
            </template>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <template v-if="item.paymentStatus === 'PAID'">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div class="v-icon" v-on="on">
                    <v-icon
                      color="green"
                      class="mr-1"
                    >
                      far fa-credit-card
                    </v-icon>
                    <v-icon color="green">
                      fas fa-check
                    </v-icon>
                  </div>
                </template>
                <span> {{ $t('Paid') }} </span>
              </v-tooltip>
            </template>
            <template v-else-if="item.paymentStatus === 'WAITING'">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div class="v-icon" v-on="on">
                    <v-icon
                      color="black"
                      class="mr-1"
                    >
                      far fa-credit-card
                    </v-icon>
                    <v-icon color="black">
                      fas fa-ellipsis-h
                    </v-icon>
                  </div>
                </template>
                <span> {{ $t('InProgress') }} </span>
              </v-tooltip>
            </template>
            <template v-else>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div class="v-icon" v-on="on">
                    <v-icon color="red" class="mr-2">
                      far fa-credit-card
                    </v-icon>
                    <v-icon color="red">
                      fas fa-exclamation-triangle
                    </v-icon>
                  </div>
                </template>
                <span> {{ $t('ErrorPayment') }} </span>
              </v-tooltip>
            </template>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <template>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-badge
                      overlap
                      bordered
                      icon="fas fa-eye"
                    >
                      <v-btn
                        :disabled="!item.stripePaymentSession"
                        small
                        color="info"
                        :href="`https://dashboard.stripe.com/payments/${item.stripePaymentSession}`"
                        v-on="on"
                      >
                        <v-icon>fab fa-cc-stripe</v-icon>
                      </v-btn>
                    </v-badge>
                  </span>
                </template>
                <span v-if="item.stripePaymentSession">
                  {{ $t('SeeOnStripe') }}
                </span>
                <span v-else> {{ $t('NoPaymentSession') }} </span>
              </v-tooltip>
              <v-tooltip top v-if="item.paymentStatus !== 'PAID'">
                <template v-slot:activator="{ on }">
                  <v-badge
                    v-on="on"
                    overlap
                    bordered
                    color="green"
                    icon="fas fa-check"
                  >
                    <v-btn
                      small
                      color="info"
                      outlined
                      class="ml-5"
                      @click="
                        currentInvoiceId = item._id
                        modalValidatePayment = true
                      "
                      v-on="on"
                    >
                      <v-icon small color="green">
                        fas fa-lock
                      </v-icon>
                    </v-btn>
                  </v-badge>
                </template>
                <span> {{ $t('ValidatePayment') }} </span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    color="primary"
                    class="ml-5"
                    @click="syncWithQuickbooksSubscriptionInvoice(item._id)"
                    :loading="syncWithQuickbooksRunning"
                    :disabled="syncWithQuickbooksRunning"
                    v-on="on"
                  >
                    <v-icon small>
                      fas fa-sync
                    </v-icon>
                  </v-btn>
                </template>
                <span> {{ $t('SyncQuickbooks') }} </span>
              </v-tooltip>
            </template>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <!-- DIALOG CANCEL SUBSCRIPTION -->
    <v-dialog v-model="modalCancelSubscription" max-width="350px">
      <v-card>
        <v-card-title> {{ $t('CancelSubscription') }} ? </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="body-1 py-4">
          <div>{{ $t('confirmCancelSubscription') }}:</div>
          <div
            v-if="brandSubscription"
            class="font-weight-bold text-center pt-2"
          >
            {{ getReadableDate(brandSubscription.nextBillingDate) }}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="error"
            class="text-none"
            @click="modalCancelSubscription = false"
          >
            <v-icon small left>
              fas fa-ban
            </v-icon>
            {{ $t('Cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="text-none"
            @click="deactivateBrandSubscription"
          >
            <v-icon small left>
              fas fa-check
            </v-icon>
            {{ $t('Confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END DIALOG CANCEL SUBSCRIPTION -->
    <!-- DIALOG REACTIVATE SUBSCRIPTION -->
    <v-dialog v-model="modalReactivateSubscription" max-width="350px">
      <v-card>
        <v-card-title> {{ $t('ReactivateSubscription') }} ? </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="body-1 py-4">
          <div>{{ $t('confirmContinueSubscription') }}:</div>
          <div
            v-if="brandSubscription"
            class="font-weight-bold text-center pt-2"
          >
            {{ getReadableDate(brandSubscription.nextBillingDate) }}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="error"
            class="text-none"
            @click="modalReactivateSubscription = false"
          >
            <v-icon small left>
              fas fa-ban
            </v-icon>
            {{ $t('Cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="text-none"
            @click="reactivateBrandSubscription"
          >
            <v-icon small left>
              fas fa-check
            </v-icon>
            {{ $t('Confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END DIALOG REACTIVATE SUBSCRIPTION -->
    <!--
        --
        -- MODAL VALIDATE PAYMENT
        --
        -->
    <v-dialog
      v-model="modalValidatePayment"
      persistent
      max-width="450px"
    >
      <v-card>
        <v-card-title class="headline blue-grey lighten-2" primary-title>
          {{ $t('ValidatePayment') }} ?
        </v-card-title>
        <v-card-text class="pa-5 body-1">
          <span>
            {{ $t('confirmation1') }}
          </span>
          <span class="green--text font-weight-bold">
            {{ $t('Paid') }}
          </span>
          <span>
            {{ $t('confirmation2') }}
          </span>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="error"
            @click="
              currentInvoiceId = null
              modalValidatePayment = false
            "
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="validateBrandSubscriptionInvoice">
            {{ $t('Confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageBrandSubscriptionAndBilling.i18n.json');

export default {
  name: 'PageBrandSubscriptionAndBilling',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      loading: false,
      brand: null,
      brandSubscription: null,
      brandInvalidAfterDatePicker: false,
      disabledBrandSubscriptionAutoRenewal: false,
      enableEditInvalidAfter: false,
      brandInvalidAfterFormatDate: '',
      brandSubscriptionRecurrence: [
        {text: this.$t('MONTHLY'), value: 'MONTHLY'},
        {text: this.$t('YEARLY'), value: 'YEARLY'},
      ],
      warehouseOffers: null,
      // brandSubscriptionPlan: [
      //   {text: this.$t('STARTUP'), value: 'STARTUP'},
      //   {text: this.$t('BASIC'), value: 'BASIC'},
      //   {text: this.$t('PRO'), value: 'PRO'},
      //   {text: this.$t('ENTERPRISE'), value: 'ENTERPRISE'}
      // ],
      brandSubscriptionPaymentMethod: [
        {text: this.$t('CARD'), value: 'CARD'},
        {text: this.$t('TRANSFER'), value: 'TRANSFER'},
        {text: this.$t('MANUAL'), value: 'MANUAL'},
      ],
      disabled: false,
      subscriptionErrorMessage: null,
      paymentMethod: null,
      modifyDate: false,
      subscriptionInvoices: [],
      headersInvoices: [],
      modalValidatePayment: false,
      currentInvoiceId: null,
      currentTrialPeriod: null,
      headersDiscounts: [],
      discounts: [],
      rules: {
        rulesTrialPeriod: [
          (value) => !!value || value === 0 || this.$t('Required'),
          (value) => value >= 0 || this.$t('MustBePositive'),
        ],
        rulesDiscountPercentage: [
          (value) => !!value || value === 0 || this.$t('Required'),
          (value) => value >= 1 || this.$t('MustBeStrictlyPositive'),
          (value) => value <= 100 || this.$t('MustBeBelow100'),
        ],
        rulesDiscountMonths: [
          (value) => !!value || value === 0 || this.$t('Required'),
          (value) => value >= 1 || this.$t('MustBeStrictlyPositive'),
        ],
      },
      idDiscount: 0,
      modalCancelSubscription: false,
      modalReactivateSubscription: false,
      subscriptionDeactivationMessage: false,
      subscriptionDeactivatedMessage: false,
      letActiveInvalidAfter: false,
      readableEndOfTrialPeriod: '',
      syncWithQuickbooksRunning: false,
    };
  },
  created() {
    this.getBrand();
    this.getWarehouseOffers();
  },
  mounted() {
    this.headersInvoices = [
      { text: this.$t('billingDate'), value: 'date', sortable: false },
      { text: this.$t('PriceTI'), value: 'price', sortable: false },
      {
        text: this.$t('Download'),
        value: 'download',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('Status'),
        value: 'status',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('Actions'),
        value: 'actions',
        sortable: false,
        align: 'center',
      },
    ];
    this.headersDiscounts = [
      {
        text: this.$t('discountPercentage'),
        value: 'discountPercentage',
        sortable: true,
      },
      {
        text: this.$t('durationMonths'),
        value: 'durationMonths',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('lifetime'),
        value: 'lifetime',
        sortable: false,
      },
      {
        text: this.$t('action'),
        value: 'action',
        sortable: false,
        align: 'center',
      },
    ];
  },
  methods: {
    formatedPlan(item) {
      const str = item.subscription.plan.replaceAll('_',' ').toLowerCase();
      const split = str.split(' ');
      for (let i = 0; i < split.length; i++) {
        split[i] = split[i][0].toUpperCase() + split[i].substring(1);
      }
      return split.join(' ');
    },
    /**
     * GET BRAND
     */
    getBrand() {
      this.loading = true;
      this.$apiInstance
        .getAdminBrand(this.$route.params.brandUUID)
        .then(
          (brandData) => {
            this.brand = brandData;
            if (this.brand.invalid_after && this.brand.invalid_after !== '') {
              const date_invalid_after = new Date(this.brand.invalid_after);
              const now = new Date();
              if (date_invalid_after > now) {
                this.subscriptionDeactivationMessage = this.$t(
                  'subscriptionDeactivationMessage'
                );
              } else {
                this.letActiveInvalidAfter = true;
                this.subscriptionDeactivatedMessage = this.$t(
                  'subscriptionDeactivatedMessage'
                );
              }
            }
            this.brandInvalidAfterFormatDate = this.getReadableDate(this.brand.invalid_after);
            /**
             * GET bRAND SUBSCRIPTION
             */
            this.getBrandSubscription();
            /**
             *  GET BRAND SUBSCRIPTION INVOICES
             */
            this.getBrandSubscriptionInvoices();
          },
          (error) => {
            /**
             * ERROR GET BRAND
             */
            this.brandErrorMessage = ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
    getWarehouseOffers() {
      this.$apiInstance.getAdminWarehouseOffers()
        .then(data => {
          this.warehouseOffers = data;
        }, (error) => {
          this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
        });
    },
    /**
     * GET BRAND SUBSCRIPTION
     */
    getBrandSubscription() {
      this.discounts = [];
      this.$apiInstance.getBrandSubscription(this.$route.params.brandUUID).then(
        (subscription) => {
          subscription.formattedNextBillingDate = this.getReadableDate(
            subscription.nextBillingDate
          );
          this.currentTrialPeriod = subscription.trial_period;

          if (subscription.discounts) {
            const keys = subscription.discounts
              ? Object.keys(subscription.discounts)
              : [];
            keys.sort(function(a, b) {
              return parseFloat(b) - parseFloat(a);
            });

            for (const key of keys) {
              this.discounts.push({
                idDiscount: this.idDiscount,
                discountPercentage: key,
                durationMonths:
                  subscription.discounts[key] > 0
                    ? subscription.discounts[key]
                    : 0,
                lifetime: subscription.discounts[key] === -1,
              });
              this.idDiscount += 1;
            }
          }

          delete subscription.discounts;
          this.brandSubscription = subscription;
          this.updateReadableEndOfTrialPeriod();

          if (this.brandSubscription.nextBillingDate) {
            this.brandSubscription.nextBillingDate = new Date(
              this.brandSubscription.nextBillingDate
            )
              .toISOString()
              .substr(0, 10);
          }
          if (this.brandSubscription.auto_renewal === false) {
            this.disabledBrandSubscriptionAutoRenewal = true;
          } else {
            this.disabledBrandSubscriptionAutoRenewal = false;
          }
        },
        (error) => {
          this.subscriptionErrorMessage = ApiErrorParser.parse(error);
        }
      );
    },
    /**
     * Format the dates to have a readable date in the UI
     */ 
    getReadableDate(date) {
      if (!date) {
        return null;
      }
      const dateObject = new Date(date);
      const day = dateObject.getDate();
      const month = dateObject.getMonth();
      const year = dateObject.getFullYear();
      const months = [
        'jan',
        'feb',
        'mar',
        'apr',
        'may',
        'jun',
        'jul',
        'aug',
        'sep',
        'oct',
        'nov',
        'dec',
      ];
      return day + ' ' + this.$t(months[month]) + ' ' + year;
    },
    /**
     * GET BRAND SUBSCRIPTION INVOICES
     */
    getBrandSubscriptionInvoices() {
      this.$apiInstance
        .getBrandSubscriptionInvoices(this.$route.params.brandUUID)
        .then((data) => {
          data.forEach((invoice) => {
            invoice.readableCreated = this.getReadableDate(
              new Date(invoice.created)
            );
            invoice.readablePrice = this.getReadablePrice(
              invoice.quickbooksObject
            );
            invoice.disabled =
              !invoice.quickbooksObject || !invoice.quickbooksObject.TotalAmt;
            invoice.loadingPdf = false;
            invoice.pdfData = null;
          });
          this.subscriptionInvoices = data;
        });
    },
    /**
    * Format the prices to have a readable price in the UI
    */ 
    getReadablePrice(qboObject) {
      if (!qboObject || !qboObject.TotalAmt) {
        return this.$t('ManualPayment');
      }
      return qboObject.TotalAmt.toFixed(2).toString() + ' €';
    },
    /**
     * GET BRAND INVOICE PDF
     */
    getSubscriptionInvoicePDF(subscriptionInvoiceItem) {
      subscriptionInvoiceItem.loadingPdf = true;
      /**
       * GET SUBSCRIPTION INVOICE PDF
       */
      this.$apiInstance
        .getBrandSubscriptionInvoicePDF(
          subscriptionInvoiceItem.brand,
          subscriptionInvoiceItem._id
        )
        .then(
          (pdf) => {
            const aRef = `download${subscriptionInvoiceItem._id}`;
            this.$refs[
              aRef
            ].download = `Subscription_Invoice_CMT_${subscriptionInvoiceItem.quickbooksObject.DocNumber}`;
            this.$refs[aRef].href = `data:application/pdf;base64,${pdf.data}`;

            this.$refs[aRef].click();
            subscriptionInvoiceItem.disabled = false;
          },
          (error) => {
            /**
             * ERROR GET QUOTE PDF
             *
             * Component BeelseNotifications used
             */
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
            subscriptionInvoiceItem.disabled = true;
          }
        )
        .finally(() => {
          subscriptionInvoiceItem.loadingPdf = false;
        });
    },
    /**
     * SyncWithQuickbooks
     */
    syncWithQuickbooksSubscriptionInvoice(subscritpionInvoiceID) {
      this.syncWithQuickbooksRunning = true;
      this.$apiInstance
        .syncQuickbooksSubscriptionInvoice(subscritpionInvoiceID)
        .finally(() => {
          this.syncWithQuickbooksRunning = false;
          this.getBrand();
        });
    },
    /**
     * VALIDATE BRAND SUBSCRIPTION INVOICES
     */
    validateBrandSubscriptionInvoice() {
      this.$apiInstance
        .validateBrandSubscriptionInvoicePayment(
          this.$route.params.brandUUID,
          this.currentInvoiceId
        )
        .finally(() => {
          this.currentInvoiceId = null;
          this.modalValidatePayment = false;
          this.getBrandSubscriptionInvoices();
        });
    },
    /**
     * MODIFY BRAND SUBSCRIPTION
     */
    modifyBrandSubscription() {
      if (
        this.$refs.brandSubscriptionForm.validate() &&
        this.$refs.discountsForm.validate()
      ) {
        const discounts = {};
        for (const discount of this.discounts) {
          if (
            discount.discountPercentage &&
            (discount.durationMonths || discount.lifetime)
          ) {
            /**
             * Due to security sanitizer, we should replace '.' by ','
             */
            discounts[discount.discountPercentage.replace('.', ',')] = discount.lifetime
              ? -1
              : parseInt(discount.durationMonths);
          }
        }
        const brandSubscription = new this.$BcmModel.ModifyBrandSubscriptionBody(
          this.brandSubscription.recurrence,
          this.brandSubscription.payment_method,
          this.brandSubscription.plan,
          parseInt(this.brandSubscription.price),
          this.brandSubscription.purchaseOrder,
          new Date(this.brand.invalid_after),
          this.brandSubscription.auto_renewal,
          parseInt(this.brandSubscription.trial_period),
          discounts
        );
        this.$apiInstance
          .modifyBrandSubscription(
            this.$route.params.brandUUID,
            brandSubscription
          )
          .then(
            () => {
              this.modifyDate = false;
              if (brandSubscription.auto_renewal === false) {
                this.disabledBrandSubscriptionAutoRenewal = true;
                this.subscriptionDeactivationMessage = null;
                this.subscriptionDeactivatedMessage = null;
              }
              this.getBrand();
              this.$notification.notify('SUCCESS',this.$t('BrandSubscriptionSuccessfullyModified'));
            },
            (error) => {
              this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
            }
          );
      }
    },
    /**
     * Disable past dates in the subscription calendar
     */
    disablePastDates(value) {
      return value >= new Date().toISOString().substr(0, 10);
    },
    addDiscountLine() {
      this.discounts.push({
        idDiscount: this.idDiscount,
        discountPercentage: 0,
        durationMonths: 0,
        lifetime: false,
      });
      this.idDiscount++;
    },
    deleteItem(item) {
      for (let index = 0; index < this.discounts.length; index++) {
        if (this.discounts[index].idDiscount === item.idDiscount) {
          this.discounts.splice(index, 1);
          break;
        }
      }
    },
    deactivateBrandSubscription() {
      this.$apiInstance.deactivateBrand(this.brand._id).then(
        (data) => {
          this.modalCancelSubscription = false;
          this.getBrand();
        },
        (error) => {
          this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
        }
      );
    },
    reactivateBrandSubscription() {
      this.$apiInstance.reactivateBrand(this.brand._id).then(
        (data) => {
          this.subscriptionDeactivationMessage = null;
          this.subscriptionDeactivatedMessage = null;
          this.modalReactivateSubscription = false;
          this.getBrand();
        },
        (error) => {
          this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
        }
      );
    },
    updateReadableEndOfTrialPeriod() {
      const trial_period = parseInt(this.brandSubscription.trial_period);
      if (trial_period < 1) {
        this.readableEndOfTrialPeriod = '';
      } else {
        const nextBillingDateObject = new Date(
          this.brandSubscription.nextBillingDate
        );

        const endOfTrialPeriodDate = new Date(
          nextBillingDateObject.setDate(
            nextBillingDateObject.getDate() +
              (trial_period - this.currentTrialPeriod)
          )
        );

        this.readableEndOfTrialPeriod =
          this.$t('endOfTrialPeriod') +
          this.getReadableDate(endOfTrialPeriodDate) +
          ', 00:00';
      }
    },
  },
};
</script>
