var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "px-3 mx-sm-5",
    attrs: {
      "flat": ""
    }
  }, [_vm.subscriptionErrorMessage ? _c('v-alert', {
    staticClass: "mt-4 col-12 text-center",
    attrs: {
      "dismissible": "",
      "text": "",
      "outlined": "",
      "type": "error"
    }
  }, [_vm._v(" " + _vm._s(_vm.subscriptionErrorMessage) + " ")]) : _vm._e(), _vm.subscriptionDeactivationMessage ? _c('v-alert', {
    staticClass: "mt-4 col-12 text-center",
    attrs: {
      "text": "",
      "outlined": "",
      "type": "info"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.subscriptionDeactivationMessage) + " " + _vm._s(_vm.getReadableDate(_vm.brand.invalid_after)) + " ")])]) : _vm._e(), _vm.subscriptionDeactivatedMessage ? _c('v-alert', {
    staticClass: "mt-4 col-12 text-center",
    attrs: {
      "text": "",
      "outlined": "",
      "type": "error"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.subscriptionDeactivatedMessage) + " " + _vm._s(_vm.getReadableDate(_vm.brand.invalid_after)) + " ")])]) : _vm._e(), _c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('Subscription')) + " "), _c('v-spacer'), _vm.brandSubscription && !_vm.disabledBrandSubscriptionAutoRenewal ? _c('div', [!_vm.subscriptionDeactivationMessage && !_vm.subscriptionDeactivatedMessage ? _c('v-btn', {
    staticClass: "mr-2 text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": function ($event) {
        _vm.modalCancelSubscription = true;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "left": ""
    }
  }, [_vm._v(" fas fa-lock ")]), _vm._v(" " + _vm._s(_vm.$t('CancelSubscription')) + " ")], 1) : _c('v-btn', {
    staticClass: "text-none mr-3",
    attrs: {
      "color": "error"
    },
    on: {
      "click": function ($event) {
        _vm.modalReactivateSubscription = true;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "left": ""
    }
  }, [_vm._v(" fas fa-lock-open ")]), _vm._v(" " + _vm._s(_vm.$t('ReactivateSubscription')) + " ")], 1)], 1) : _vm._e()], 1), _vm.brandSubscription ? _c('v-card-text', {
    staticClass: "pb-0"
  }, [_c('v-form', {
    ref: "brandSubscriptionForm"
  }, [_c('v-row', {
    staticClass: "justify-space-around"
  }, [_c('v-col', {
    staticClass: "col-12 col-md-6 pb-0"
  }, [_c('v-select', {
    attrs: {
      "menu-props": {
        offsetY: true
      },
      "items": _vm.warehouseOffers,
      "item-text": _vm.formatedPlan,
      "item-value": "subscription.plan",
      "label": _vm.$t('Plan'),
      "outlined": "",
      "disabled": !_vm.disabledBrandSubscriptionAutoRenewal && !!_vm.subscriptionDeactivationMessage || !!_vm.subscriptionDeactivatedMessage
    },
    model: {
      value: _vm.brandSubscription.plan,
      callback: function ($$v) {
        _vm.$set(_vm.brandSubscription, "plan", $$v);
      },
      expression: "brandSubscription.plan"
    }
  }), _c('v-select', {
    attrs: {
      "menu-props": {
        offsetY: true
      },
      "items": _vm.brandSubscriptionPaymentMethod,
      "label": _vm.$t('PaymentMethod'),
      "outlined": "",
      "disabled": !_vm.disabledBrandSubscriptionAutoRenewal && !!_vm.subscriptionDeactivationMessage || !!_vm.subscriptionDeactivatedMessage
    },
    model: {
      value: _vm.brandSubscription.payment_method,
      callback: function ($$v) {
        _vm.$set(_vm.brandSubscription, "payment_method", $$v);
      },
      expression: "brandSubscription.payment_method"
    }
  }), _c('v-select', {
    attrs: {
      "menu-props": {
        offsetY: true
      },
      "items": _vm.brandSubscriptionRecurrence,
      "label": _vm.$t('Recurrence'),
      "outlined": "",
      "disabled": !_vm.disabledBrandSubscriptionAutoRenewal && !!_vm.subscriptionDeactivationMessage || !!_vm.subscriptionDeactivatedMessage
    },
    model: {
      value: _vm.brandSubscription.recurrence,
      callback: function ($$v) {
        _vm.$set(_vm.brandSubscription, "recurrence", $$v);
      },
      expression: "brandSubscription.recurrence"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "number",
      "label": _vm.$t('Price'),
      "outlined": "",
      "disabled": !_vm.disabledBrandSubscriptionAutoRenewal && !!_vm.subscriptionDeactivationMessage || !!_vm.subscriptionDeactivatedMessage
    },
    model: {
      value: _vm.brandSubscription.price,
      callback: function ($$v) {
        _vm.$set(_vm.brandSubscription, "price", $$v);
      },
      expression: "brandSubscription.price"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('PurchaseOrder'),
      "outlined": "",
      "disabled": !_vm.disabledBrandSubscriptionAutoRenewal && !!_vm.subscriptionDeactivationMessage || !!_vm.subscriptionDeactivatedMessage,
      "maxlength": 29,
      "counter": ""
    },
    model: {
      value: _vm.brandSubscription.purchaseOrder,
      callback: function ($$v) {
        _vm.$set(_vm.brandSubscription, "purchaseOrder", $$v);
      },
      expression: "brandSubscription.purchaseOrder"
    }
  }), _c('v-checkbox', {
    staticClass: "mt-0 pt-0 mb-2",
    attrs: {
      "label": _vm.$t('AutoRenewal'),
      "inset": "",
      "color": "success",
      "disabled": !_vm.disabledBrandSubscriptionAutoRenewal && !!_vm.subscriptionDeactivationMessage || !!_vm.subscriptionDeactivatedMessage
    },
    model: {
      value: _vm.brandSubscription.auto_renewal,
      callback: function ($$v) {
        _vm.$set(_vm.brandSubscription, "auto_renewal", $$v);
      },
      expression: "brandSubscription.auto_renewal"
    }
  }), !_vm.brandSubscription.auto_renewal ? _c('v-menu', {
    ref: "brandInvalidAfterDatePicker",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.brandInvalidAfterFormatDate,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    on: {
      "update:returnValue": function ($event) {
        _vm.brandInvalidAfterFormatDate = $event;
      },
      "update:return-value": function ($event) {
        _vm.brandInvalidAfterFormatDate = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": _vm.$t('endDateSubscription'),
            "append-icon": "fas fa-calendar",
            "readonly": "",
            "outlined": "",
            "disabled": !_vm.disabledBrandSubscriptionAutoRenewal && _vm.letActiveInvalidAfter && (!!_vm.subscriptionDeactivationMessage || !!_vm.subscriptionDeactivatedMessage)
          },
          model: {
            value: _vm.brandInvalidAfterFormatDate,
            callback: function ($$v) {
              _vm.brandInvalidAfterFormatDate = $$v;
            },
            expression: "brandInvalidAfterFormatDate"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 3871492027),
    model: {
      value: _vm.brandInvalidAfterDatePicker,
      callback: function ($$v) {
        _vm.brandInvalidAfterDatePicker = $$v;
      },
      expression: "brandInvalidAfterDatePicker"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "allowed-dates": _vm.disablePastDates,
      "no-title": "",
      "scrollable": ""
    },
    model: {
      value: _vm.brand.invalid_after,
      callback: function ($$v) {
        _vm.$set(_vm.brand, "invalid_after", $$v);
      },
      expression: "brand.invalid_after"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.brandInvalidAfterDatePicker = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")]), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.brandInvalidAfterDatePicker.save(_vm.brand.invalid_after);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Confirm')) + " ")])], 1)], 1) : _vm._e(), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('nextBillingDate'),
      "outlined": "",
      "disabled": true
    },
    model: {
      value: _vm.brandSubscription.formattedNextBillingDate,
      callback: function ($$v) {
        _vm.$set(_vm.brandSubscription, "formattedNextBillingDate", $$v);
      },
      expression: "brandSubscription.formattedNextBillingDate"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "number",
      "rules": _vm.rules.rulesTrialPeriod,
      "label": _vm.$t('trialPeriod'),
      "disabled": _vm.currentTrialPeriod === 0,
      "outlined": "",
      "hint": _vm.readableEndOfTrialPeriod,
      "persistent-hint": _vm.readableEndOfTrialPeriod !== ''
    },
    on: {
      "input": _vm.updateReadableEndOfTrialPeriod
    },
    model: {
      value: _vm.brandSubscription.trial_period,
      callback: function ($$v) {
        _vm.$set(_vm.brandSubscription, "trial_period", $$v);
      },
      expression: "brandSubscription.trial_period"
    }
  })], 1), _c('v-col', {
    staticClass: "col-12 col-md-5 pb-0"
  }, [_c('v-form', {
    ref: "discountsForm"
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.headersDiscounts,
      "items": _vm.discounts,
      "mobile-breakpoint": "0",
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c('v-toolbar', {
          staticClass: "pr-5",
          staticStyle: {
            "font-size": "18px"
          },
          attrs: {
            "flat": "",
            "color": "white"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('discounts')) + " "), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "fab": "",
            "x-small": "",
            "color": "info",
            "disabled": !_vm.disabledBrandSubscriptionAutoRenewal && !!_vm.subscriptionDeactivationMessage || !!_vm.subscriptionDeactivatedMessage
          },
          on: {
            "click": _vm.addDiscountLine
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-plus ")])], 1)], 1)];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('tr', {
          staticClass: "align-center border-green"
        }, [_c('td', [_c('v-text-field', {
          attrs: {
            "type": "number",
            "rules": _vm.rules.rulesDiscountPercentage,
            "dense": "",
            "disabled": !_vm.disabledBrandSubscriptionAutoRenewal && !!_vm.subscriptionDeactivationMessage || !!_vm.subscriptionDeactivatedMessage
          },
          model: {
            value: item.discountPercentage,
            callback: function ($$v) {
              _vm.$set(item, "discountPercentage", $$v);
            },
            expression: "item.discountPercentage"
          }
        })], 1), !item.lifetime ? _c('td', [_c('v-text-field', {
          attrs: {
            "type": "number",
            "rules": _vm.rules.rulesDiscountMonths,
            "dense": "",
            "disabled": !_vm.disabledBrandSubscriptionAutoRenewal && !!_vm.subscriptionDeactivationMessage || !!_vm.subscriptionDeactivatedMessage
          },
          model: {
            value: item.durationMonths,
            callback: function ($$v) {
              _vm.$set(item, "durationMonths", $$v);
            },
            expression: "item.durationMonths"
          }
        })], 1) : _c('td', [_c('v-text-field', {
          staticClass: "text-center",
          attrs: {
            "dense": "",
            "value": "Inf",
            "disabled": !_vm.disabledBrandSubscriptionAutoRenewal && !!_vm.subscriptionDeactivationMessage || !!_vm.subscriptionDeactivatedMessage
          }
        })], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('v-checkbox', {
          attrs: {
            "disabled": !_vm.disabledBrandSubscriptionAutoRenewal && !!_vm.subscriptionDeactivationMessage || !!_vm.subscriptionDeactivatedMessage
          },
          model: {
            value: item.lifetime,
            callback: function ($$v) {
              _vm.$set(item, "lifetime", $$v);
            },
            expression: "item.lifetime"
          }
        })], 1), _c('td', [_c('v-btn', {
          attrs: {
            "small": "",
            "rounded": "",
            "color": "error",
            "disabled": !_vm.disabledBrandSubscriptionAutoRenewal && !!_vm.subscriptionDeactivationMessage || !!_vm.subscriptionDeactivatedMessage
          },
          on: {
            "click": function ($event) {
              return _vm.deleteItem(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-trash ")])], 1)], 1)])];
      }
    }], null, false, 884024148)
  })], 1)], 1)], 1)], 1)], 1) : _vm._e(), _c('v-card-actions', {
    staticClass: "pl-4 pb-8"
  }, [_c('v-row', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "error",
      "loading": _vm.loading,
      "disabled": _vm.loading || !!_vm.subscriptionDeactivatedMessage || !!_vm.subscriptionDeactivationMessage && !_vm.disabledBrandSubscriptionAutoRenewal,
      "ripple": false
    },
    on: {
      "click": function ($event) {
        _vm.getBrandSubscription();
        _vm.getBrandSubscriptionInvoices();
        _vm.modifyDate = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fa fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success",
      "loading": _vm.loading,
      "disabled": (_vm.loading || !!_vm.subscriptionDeactivatedMessage || !!_vm.subscriptionDeactivationMessage) && !_vm.disabledBrandSubscriptionAutoRenewal && _vm.letActiveInvalidAfter,
      "ripple": false
    },
    on: {
      "click": _vm.modifyBrandSubscription
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fa fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Submit')) + " ")], 1)], 1)], 1)], 1), _c('v-divider', {
    staticClass: "mx-7"
  }), _vm.subscriptionInvoices ? _c('v-card', {
    staticClass: "px-3 mx-sm-5 mt-2",
    attrs: {
      "elevation": "0",
      "flat": ""
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('Invoices')) + " ")]), _c('v-card-text', [_vm.subscriptionInvoices ? _c('v-data-table', {
    attrs: {
      "headers": _vm.headersInvoices,
      "items": _vm.subscriptionInvoices,
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      },
      "sort-by": "date",
      "mobile-breakpoint": "0"
    },
    scopedSlots: _vm._u([{
      key: `item.date`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [[_c('span', [_vm._v(" " + _vm._s(item.readableCreated) + " ")])]];
      }
    }, {
      key: `item.price`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [[_c('span', [_vm._v(_vm._s(item.readablePrice) + " ")])]];
      }
    }, {
      key: `item.download`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [[_c('a', {
          ref: `download${item._id}`,
          staticClass: "d-none"
        }), _c('v-btn', {
          staticClass: "text-none white--text",
          attrs: {
            "color": "orange",
            "target": "_blank",
            "loading": item.loadingPdf,
            "disabled": item.disabled,
            "ripple": false
          },
          on: {
            "click": function ($event) {
              return _vm.getSubscriptionInvoicePDF(item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-download ")]), _vm._v(" PDF ")], 1)]];
      }
    }, {
      key: `item.status`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [item.paymentStatus === 'PAID' ? [_c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref7) {
              var on = _ref7.on;
              return [_c('div', _vm._g({
                staticClass: "v-icon"
              }, on), [_c('v-icon', {
                staticClass: "mr-1",
                attrs: {
                  "color": "green"
                }
              }, [_vm._v(" far fa-credit-card ")]), _c('v-icon', {
                attrs: {
                  "color": "green"
                }
              }, [_vm._v(" fas fa-check ")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('Paid')) + " ")])])] : item.paymentStatus === 'WAITING' ? [_c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref8) {
              var on = _ref8.on;
              return [_c('div', _vm._g({
                staticClass: "v-icon"
              }, on), [_c('v-icon', {
                staticClass: "mr-1",
                attrs: {
                  "color": "black"
                }
              }, [_vm._v(" far fa-credit-card ")]), _c('v-icon', {
                attrs: {
                  "color": "black"
                }
              }, [_vm._v(" fas fa-ellipsis-h ")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('InProgress')) + " ")])])] : [_c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref9) {
              var on = _ref9.on;
              return [_c('div', _vm._g({
                staticClass: "v-icon"
              }, on), [_c('v-icon', {
                staticClass: "mr-2",
                attrs: {
                  "color": "red"
                }
              }, [_vm._v(" far fa-credit-card ")]), _c('v-icon', {
                attrs: {
                  "color": "red"
                }
              }, [_vm._v(" fas fa-exclamation-triangle ")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('ErrorPayment')) + " ")])])]];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref10) {
        var item = _ref10.item;
        return [[_c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref11) {
              var on = _ref11.on;
              return [_c('span', _vm._g({}, on), [_c('v-badge', {
                attrs: {
                  "overlap": "",
                  "bordered": "",
                  "icon": "fas fa-eye"
                }
              }, [_c('v-btn', _vm._g({
                attrs: {
                  "disabled": !item.stripePaymentSession,
                  "small": "",
                  "color": "info",
                  "href": `https://dashboard.stripe.com/payments/${item.stripePaymentSession}`
                }
              }, on), [_c('v-icon', [_vm._v("fab fa-cc-stripe")])], 1)], 1)], 1)];
            }
          }], null, true)
        }, [item.stripePaymentSession ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('SeeOnStripe')) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('NoPaymentSession')) + " ")])]), item.paymentStatus !== 'PAID' ? _c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref12) {
              var on = _ref12.on;
              return [_c('v-badge', _vm._g({
                attrs: {
                  "overlap": "",
                  "bordered": "",
                  "color": "green",
                  "icon": "fas fa-check"
                }
              }, on), [_c('v-btn', _vm._g({
                staticClass: "ml-5",
                attrs: {
                  "small": "",
                  "color": "info",
                  "outlined": ""
                },
                on: {
                  "click": function ($event) {
                    _vm.currentInvoiceId = item._id;
                    _vm.modalValidatePayment = true;
                  }
                }
              }, on), [_c('v-icon', {
                attrs: {
                  "small": "",
                  "color": "green"
                }
              }, [_vm._v(" fas fa-lock ")])], 1)], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('ValidatePayment')) + " ")])]) : _vm._e(), _c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref13) {
              var on = _ref13.on;
              return [_c('v-btn', _vm._g({
                staticClass: "ml-5",
                attrs: {
                  "small": "",
                  "color": "primary",
                  "loading": _vm.syncWithQuickbooksRunning,
                  "disabled": _vm.syncWithQuickbooksRunning
                },
                on: {
                  "click": function ($event) {
                    return _vm.syncWithQuickbooksSubscriptionInvoice(item._id);
                  }
                }
              }, on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v(" fas fa-sync ")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('SyncQuickbooks')) + " ")])])]];
      }
    }], null, true)
  }) : _vm._e()], 1)], 1) : _vm._e(), _c('v-dialog', {
    attrs: {
      "max-width": "350px"
    },
    model: {
      value: _vm.modalCancelSubscription,
      callback: function ($$v) {
        _vm.modalCancelSubscription = $$v;
      },
      expression: "modalCancelSubscription"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('CancelSubscription')) + " ? ")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "body-1 py-4"
  }, [_c('div', [_vm._v(_vm._s(_vm.$t('confirmCancelSubscription')) + ":")]), _vm.brandSubscription ? _c('div', {
    staticClass: "font-weight-bold text-center pt-2"
  }, [_vm._v(" " + _vm._s(_vm.getReadableDate(_vm.brandSubscription.nextBillingDate)) + " ")]) : _vm._e()]), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": function ($event) {
        _vm.modalCancelSubscription = false;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "left": ""
    }
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.deactivateBrandSubscription
    }
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "left": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Confirm')) + " ")], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "350px"
    },
    model: {
      value: _vm.modalReactivateSubscription,
      callback: function ($$v) {
        _vm.modalReactivateSubscription = $$v;
      },
      expression: "modalReactivateSubscription"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('ReactivateSubscription')) + " ? ")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "body-1 py-4"
  }, [_c('div', [_vm._v(_vm._s(_vm.$t('confirmContinueSubscription')) + ":")]), _vm.brandSubscription ? _c('div', {
    staticClass: "font-weight-bold text-center pt-2"
  }, [_vm._v(" " + _vm._s(_vm.getReadableDate(_vm.brandSubscription.nextBillingDate)) + " ")]) : _vm._e()]), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": function ($event) {
        _vm.modalReactivateSubscription = false;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "left": ""
    }
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.reactivateBrandSubscription
    }
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "left": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Confirm')) + " ")], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "450px"
    },
    model: {
      value: _vm.modalValidatePayment,
      callback: function ($$v) {
        _vm.modalValidatePayment = $$v;
      },
      expression: "modalValidatePayment"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline blue-grey lighten-2",
    attrs: {
      "primary-title": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ValidatePayment')) + " ? ")]), _c('v-card-text', {
    staticClass: "pa-5 body-1"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('confirmation1')) + " ")]), _c('span', {
    staticClass: "green--text font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Paid')) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('confirmation2')) + " ")])]), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": function ($event) {
        _vm.currentInvoiceId = null;
        _vm.modalValidatePayment = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.validateBrandSubscriptionInvoice
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Confirm')) + " ")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }